import React from 'react';
import {Link} from 'react-router-dom';
//import ModalVideo from 'react-modal-video';
//import { FaPlay } from "react-icons/fa";
import HeroImg1 from '../../assets/images/hero1.jpg';
import HeroImg2 from '../../assets/images/hero/1.jpg';

const Hero = () => {
    
    return (
        <>
            
            <div className=" hero-area height-800 bg-property d-flex align-items-center" data-overlay="black" data-opacity="7" style={{ backgroundImage: `url(${HeroImg2})` }}>
            <div className="container altezza">
    <div className="row altezza">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
            <div className="hero-wrapper">
                <h1>Benvenuti da Vertical Services</h1>
                <p>Edilizia e giardinaggio dalla A alla Z su fune. 
                    <br /> Sempre a tua disposizione.</p>
            </div>
        </div>
      
    </div>
</div>

            </div>
        </>
    )
}

export default Hero;