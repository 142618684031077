import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import AboutExperienceImg1 from '../../assets/images/about/4.jpg';

const AboutExperienceData = [
    
    {
        id: uuidv4(),
        heading: 'Come lavoriamo',
        description: "Innovativa e rivoluzionaria nell'approccio, Vertical Services nasce dalla passione per la cura del verde di persone giovani, tenaci e preparate che hanno fatto del rispetto della pianta il punto di partenza per garantirne lo stato di salute. Avvalendoci delle più moderne attrezzature, certificate in termini di sicurezza e affidabilità, siamo in grado di offrire la massima qualità e garanzia di risultato in ogni servizio dell'ampia gamma di lavori su corda che proponiamo a Milano e dintorni. Sposando la filosofia che pone anzi tutto la tutela e la salvaguardia di qualsiasi specie di pianta e albero, ci prendiamo a cuore il recupero di esemplari capitozzati o precedentemente danneggiati da interventi massivi, con un programma di operazioni mirate a ristabilire l'equilibrio di cui ogni pianta necessita per vivere in salute. L'obbiettivo è quello di diffondere una cultura della pianta diversa, che si basi sul rispetto assoluto della natura. Oltre a treeclimbing, potatura alberi, abbattimento controllato e consolidamento, Vertical Services offre innumerevoli servizi di edilizia su fune che coprono sia lavori di pulizia approfondita che opere di muratura, il tutto eseguito con una cura meticolosa per i dettagli.",
    },

   

    
    
];

const AboutExperienceItem = ({ heading, description, img }) => {
    return (
        <>
            <div className="single-experience">
                <h4>{heading}</h4>
                <p>{description}</p>
            </div>
        </>
    )
}

const AboutExperience = () => {
    return (
        <>
            <div className="experience-area pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title text-center mb-70">
                                <span>We Have 20 Years of Experience</span>
                                <h2>We Have 20+ years of Experience  in <br /> Landscape & Gardening Services</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 offset-xl-2 offset-lg-2 offset-md-2">
                            {AboutExperienceData.map((data) => (
                                <AboutExperienceItem
                                    key={data.id}
                                    heading={data.heading}
                                    description={data.description}
                                />
                            ))}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-lg-6 pl-85 pr-0">
                            <div className="experience-img">
                                <img src={AboutExperienceImg1} alt="" />
                                <div className="experience-small-block">
                                    <h5>We are Since 2000 ! Over 200 Awards </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutExperience;