import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Icon_landscape from '../../assets/images/service/landscape.png';
import Icon_growing from '../../assets/images/service/growing.png';
import Icon_harvest from '../../assets/images/service/harvest.png';
import Icon_flower from '../../assets/images/service/flower.png';
import Icon_tree from '../../assets/images/service/tree.png';
import Icon_rubbish from '../../assets/images/service/rubbish.png';
import { FaLongArrowAltRight } from 'react-icons/fa';

const ServiceData = [
    {
        id: uuidv4(),
        heading: 'Treeclimbing',
        description: 'Potature e cure sugli alberi direttamente tra i rami, senza invasività. Operiamo su corda, senza impalcature.',
        img: Icon_landscape,
        serviceLink: '/service-details',
    },
    {
        id: uuidv4(),
        heading: 'Edilizia su fune',
        description: 'Lavoriamo su fune, abbattendo i costi e operando in spazi difficili. Specializzati in ristrutturazioni, messe in sicurezza e verniciature.',
        img: Icon_growing,
        serviceLink: '/service-details',
    },
    {
        id: uuidv4(),
        heading: 'Pulizia vetrate',
        description: 'La nostra tecnica innovativa su fune elimina i ponteggi per la pulizia di vetrate, riducendo costi e disagi per case, palazzi e grattacieli.',
        img: Icon_harvest,
        serviceLink: '/service-details',
    },
    {
        id: uuidv4(),
        heading: 'Pulizia gronde e tetti',
        description: 'Per un tetto sempre in ottime condizioni, offriamo servizi di pulizia, riparazione, sostituzione di tegole e cura di canne fumarie.',
        img: Icon_flower,
        serviceLink: '/service-details',
    },
    {
        id: uuidv4(),
        heading: 'Abbattimento controllato',
        description: "L'abbattimento controllato in tree climbing è ideale per alberi situati in spazi ristretti o nelle estreme vicinanze di abitazioni.",
        img: Icon_tree,
        serviceLink: '/service-details',
    },
    {
        id: uuidv4(),
        heading: 'Manutenzione verde',
        description: 'Offriamo taglio erba, potatura e disinfestazione per privati e aziende, oltre a posa del prato e sistemi di irrigazione avanzati.',
        img: Icon_rubbish,
        serviceLink: '/service-details',
    },

];

const ServiceItem = ({ img, heading, description, serviceLink }) => {
    return (
        <>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="single-service">
                    <div className="service-icon">
                        <img src={img} alt="Service Icon" />
                    </div>
                    <h3>{heading}</h3>
                    <p>{description}</p>
                  
                </div>
            </div>
        </>
    )
}

const Service = () => {
    return (
        <>
            <div className="service-area bg-4 pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title text-center mb-70">
                                <span>I nostri servizi</span>
                                <h2>I più richiesti<br/>Servizi di giardinaggio e edilizia</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {ServiceData.map((data) => (
                            <ServiceItem
                                key={data.id}
                                img={data.img}
                                heading={data.heading}
                                description={data.description}
                                serviceLink={data.serviceLink}
                            />
                        ))}
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className="single-service-large">
                                <h3>Siamo professionisti nel <span>Giardinaggio & Edilizia su fune</span></h3>
                                <p>Offriamo servizi di giardinaggio e edilizia su fune con precisione e sicurezza, utilizzando tecniche avanzate per risultati eccellenti.</p>
                                <a href="/contact" className="l-btn">Contattaci</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Service;