import React from 'react';
import {Link} from 'react-router-dom';
import FooterBG from '../../assets/images/footer.png';
import { FaPhoneAlt, FaFacebookF, FaTwitter, FaInstagram, FaPinterestP, FaAngleDoubleRight, FaMailBulk } from 'react-icons/fa';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer-top bg-property theme-bg-2 pt-110 pb-80" style={{ backgroundImage: `url(${FooterBG})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-widget-1">
                                    <div className="footer-title">
                                    <h4><a href="mailto:info@verticalservices.it">Contattaci!</a></h4>

                                    </div>
                                    <div className="footer-text">
                                        <p>Per qualsiasi domanda non esitare a contattarci</p>
                                    </div>
                                    <div className="footer-info">
                                        <Link to="/"><FaPhoneAlt /> 391 454 6476</Link>
                                    </div>
                                    
                                    <div className="footer-info">
                                        <Link to="/"><FaMailBulk /> info@verticalservices.it</Link>
                                    </div>

                                </div>
                            </div>
                        
                     
                        
                        </div>
                    </div>
                </div>

            
            </footer>
        </>
    )
}

export default Footer;