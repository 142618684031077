import React from 'react';
import HomeAboutImg from '../../assets/images/about/about.jpg';

const HomeAbout = () => {
    return (
        <>
            <div className="about-area gray-bg-2 pt-200 pb-160">
                <div className="container ">
                    <div className="row ">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="about-img-style-1 sfondo-bianco">
        
                          







                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 sfondo-bianco">
                            <div className="about-text-wrapper pt-30 ">
                                <div className="section-title mb-40">
                                    <span>Il benessere della pianta prima di tutto</span>
                                    <h2 className="mb-30">Come lavoriamo</h2>
                                    <p>Innovativa e rivoluzionaria nell'approccio, Vertical Services nasce dalla passione per la cura del verde di persone giovani, tenaci e preparate che hanno fatto del rispetto della pianta il punto di partenza per garantirne lo stato di salute. Avvalendoci delle più moderne attrezzature, certificate in termini di sicurezza e affidabilità, siamo in grado di offrire la massima qualità e garanzia di risultato in ogni servizio dell'ampia gamma di lavori su corda che proponiamo a Milano e dintorni. Sposando la filosofia che pone anzi tutto la tutela e la salvaguardia di qualsiasi specie di pianta e albero, ci prendiamo a cuore il recupero di esemplari capitozzati o precedentemente danneggiati da interventi massivi, con un programma di operazioni mirate a ristabilire l'equilibrio di cui ogni pianta necessita per vivere in salute. L'obbiettivo è quello di diffondere una cultura della pianta diversa, che si basi sul rispetto assoluto della natura. Oltre a treeclimbing, potatura alberi, abbattimento controllato e consolidamento, Vertical Services offre innumerevoli servizi di edilizia su fune che coprono sia lavori di pulizia approfondita che opere di muratura, il tutto eseguito con una cura meticolosa per i dettagli.</p>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeAbout;