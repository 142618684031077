import React from 'react';
import ServiceDetailsImg1 from '../../assets/images/service/1.jpg';

const ServiceDetailsContent = () => {
    return (
        <>
            <h2>Know more us of our landi Gardening and Landscape Services.</h2>
            <p>
                It is a long established fact that a reader will be distracted by the readable content of page when looking layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, using Content here, content here', making
                it look like readable English.
                <br /> <br /> Which is the same as saying through shrinking from toil and pain. These cases are perfectly hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every
                pleasure is to be welcomed and every pain avoided.
            </p>
            <img src={ServiceDetailsImg1} className="mt-35 mb-35" alt="" />
            <h2>Why we are giving Gardening Services Since 2000, you can trust us for</h2>
            <p>Long established fact that a reader will be distracted by the readable content of a page when at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, opposed to using 'Content
                here, content here', making it look like readable English.
                <br /><br /> Many desktop publishing and web page editors now use Lorem Ipsum as their default model text, and search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over
                the years, sometimes by accident, sometimes on purpose (injected humour and the like).

            </p>
            <h2>Working Process</h2>
            <p className="mb-45">When our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing the claims of duty or
                the obligations of business</p>
        </>
    )
}

export default ServiceDetailsContent;