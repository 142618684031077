import React from 'react';
import { FaPhoneAlt, FaClock, FaMailBulk } from 'react-icons/fa';

const HeaderTop = () => {
    return (
        <div className="header-top pt-15 pb-15 bg-1">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="header-info">
                            <ul>
                                <li>
                                    <FaPhoneAlt /> Chiamaci ora :
                                     391 454 6476
                                </li>
                                <li>
    <FaMailBulk /> <a href="mailto:info@verticalservices.it">info@verticalservices.it</a>
</li>

                            </ul>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    )
}

export default HeaderTop;